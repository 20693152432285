import Vue from 'vue'
import VeeValidate from 'vee-validate'
import validateMessagesJa from '../validate/messages/customJa'
import {
  fullKana,
  validatePhone,
  minlengthNumeric,
  mailAddress,
  holderName,
  surrogatePair
} from '../validate/customValidate'

Vue.use(VeeValidate, {
  locale: 'ja',
  dictionary: {
    ja: validateMessagesJa
  }
})

VeeValidate.Validator.extend('fullKana', fullKana)
VeeValidate.Validator.extend('validatePhone', validatePhone)
VeeValidate.Validator.extend('minlengthNumeric', minlengthNumeric)
VeeValidate.Validator.extend('mailAddress', mailAddress)
VeeValidate.Validator.extend('holderName', holderName)
VeeValidate.Validator.extend('surrogatePair', surrogatePair)
