import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23d2f299 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _3b46f5ae = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _0cfdb88c = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _996c559c = () => interopDefault(import('../pages/member/complete/index.vue' /* webpackChunkName: "pages/member/complete/index" */))
const _7d2c9c06 = () => interopDefault(import('../pages/member/confirm/index.vue' /* webpackChunkName: "pages/member/confirm/index" */))
const _1280be84 = () => interopDefault(import('../pages/mypage/contact/index.vue' /* webpackChunkName: "pages/mypage/contact/index" */))
const _2232bc8a = () => interopDefault(import('../pages/mypage/contract/index.vue' /* webpackChunkName: "pages/mypage/contract/index" */))
const _05dc57b2 = () => interopDefault(import('../pages/mypage/hospital/index.vue' /* webpackChunkName: "pages/mypage/hospital/index" */))
const _1b3e9bd6 = () => interopDefault(import('../pages/mypage/lovot/index.vue' /* webpackChunkName: "pages/mypage/lovot/index" */))
const _1597c59f = () => interopDefault(import('../pages/mypage/myaccount/index.vue' /* webpackChunkName: "pages/mypage/myaccount/index" */))
const _3b52a0ea = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _25baff92 = () => interopDefault(import('../pages/mypage/official-supporters/index.vue' /* webpackChunkName: "pages/mypage/official-supporters/index" */))
const _77850540 = () => interopDefault(import('../pages/mypage/contact/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/complete/index" */))
const _5421482f = () => interopDefault(import('../pages/mypage/contact/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/confirm/index" */))
const _4b3c0d03 = () => interopDefault(import('../pages/mypage/contact/history/index.vue' /* webpackChunkName: "pages/mypage/contact/history/index" */))
const _1c15e5f6 = () => interopDefault(import('../pages/mypage/contract/billing/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/index" */))
const _0bf1ff6b = () => interopDefault(import('../pages/mypage/contract/billing_address/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/index" */))
const _2423b978 = () => interopDefault(import('../pages/mypage/contract/creditcard/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/index" */))
const _6b0157e4 = () => interopDefault(import('../pages/mypage/hospital/detail/index.vue' /* webpackChunkName: "pages/mypage/hospital/detail/index" */))
const _17fff84f = () => interopDefault(import('../pages/mypage/lovot/owners/index.vue' /* webpackChunkName: "pages/mypage/lovot/owners/index" */))
const _4a96cdb8 = () => interopDefault(import('../pages/mypage/lovot/update/index.vue' /* webpackChunkName: "pages/mypage/lovot/update/index" */))
const _9bf53414 = () => interopDefault(import('../pages/mypage/myaccount/change/index.vue' /* webpackChunkName: "pages/mypage/myaccount/change/index" */))
const _0abafc10 = () => interopDefault(import('../pages/mypage/myaccount/confirm/index.vue' /* webpackChunkName: "pages/mypage/myaccount/confirm/index" */))
const _3fbb3573 = () => interopDefault(import('../pages/mypage/contact/history/detail/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/index" */))
const _a64e62ac = () => interopDefault(import('../pages/mypage/contract/billing_address/change/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/change/index" */))
const _6a54a9dc = () => interopDefault(import('../pages/mypage/contract/billing_address/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/confirm/index" */))
const _115bf520 = () => interopDefault(import('../pages/mypage/contract/billing/detail/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/detail/index" */))
const _a343569e = () => interopDefault(import('../pages/mypage/contract/creditcard/change/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/change/index" */))
const _cd003696 = () => interopDefault(import('../pages/mypage/contract/creditcard/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/confirm/index" */))
const _463cec2b = () => interopDefault(import('../pages/mypage/contact/history/detail/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/complete/index" */))
const _52a95038 = () => interopDefault(import('../pages/mypage/contact/history/detail/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _23d2f299,
    name: "404"
  }, {
    path: "/error",
    component: _3b46f5ae,
    name: "error"
  }, {
    path: "/member",
    component: _0cfdb88c,
    name: "member"
  }, {
    path: "/member/complete",
    component: _996c559c,
    name: "member-complete"
  }, {
    path: "/member/confirm",
    component: _7d2c9c06,
    name: "member-confirm"
  }, {
    path: "/mypage/contact",
    component: _1280be84,
    name: "mypage-contact"
  }, {
    path: "/mypage/contract",
    component: _2232bc8a,
    name: "mypage-contract"
  }, {
    path: "/mypage/hospital",
    component: _05dc57b2,
    name: "mypage-hospital"
  }, {
    path: "/mypage/lovot",
    component: _1b3e9bd6,
    name: "mypage-lovot"
  }, {
    path: "/mypage/myaccount",
    component: _1597c59f,
    name: "mypage-myaccount"
  }, {
    path: "/mypage/news",
    component: _3b52a0ea,
    name: "mypage-news"
  }, {
    path: "/mypage/official-supporters",
    component: _25baff92,
    name: "mypage-official-supporters"
  }, {
    path: "/mypage/contact/complete",
    component: _77850540,
    name: "mypage-contact-complete"
  }, {
    path: "/mypage/contact/confirm",
    component: _5421482f,
    name: "mypage-contact-confirm"
  }, {
    path: "/mypage/contact/history",
    component: _4b3c0d03,
    name: "mypage-contact-history"
  }, {
    path: "/mypage/contract/billing",
    component: _1c15e5f6,
    name: "mypage-contract-billing"
  }, {
    path: "/mypage/contract/billing_address",
    component: _0bf1ff6b,
    name: "mypage-contract-billing_address"
  }, {
    path: "/mypage/contract/creditcard",
    component: _2423b978,
    name: "mypage-contract-creditcard"
  }, {
    path: "/mypage/hospital/detail",
    component: _6b0157e4,
    name: "mypage-hospital-detail"
  }, {
    path: "/mypage/lovot/owners",
    component: _17fff84f,
    name: "mypage-lovot-owners"
  }, {
    path: "/mypage/lovot/update",
    component: _4a96cdb8,
    name: "mypage-lovot-update"
  }, {
    path: "/mypage/myaccount/change",
    component: _9bf53414,
    name: "mypage-myaccount-change"
  }, {
    path: "/mypage/myaccount/confirm",
    component: _0abafc10,
    name: "mypage-myaccount-confirm"
  }, {
    path: "/mypage/contact/history/detail",
    component: _3fbb3573,
    name: "mypage-contact-history-detail"
  }, {
    path: "/mypage/contract/billing_address/change",
    component: _a64e62ac,
    name: "mypage-contract-billing_address-change"
  }, {
    path: "/mypage/contract/billing_address/confirm",
    component: _6a54a9dc,
    name: "mypage-contract-billing_address-confirm"
  }, {
    path: "/mypage/contract/billing/detail",
    component: _115bf520,
    name: "mypage-contract-billing-detail"
  }, {
    path: "/mypage/contract/creditcard/change",
    component: _a343569e,
    name: "mypage-contract-creditcard-change"
  }, {
    path: "/mypage/contract/creditcard/confirm",
    component: _cd003696,
    name: "mypage-contract-creditcard-confirm"
  }, {
    path: "/mypage/contact/history/detail/complete",
    component: _463cec2b,
    name: "mypage-contact-history-detail-complete"
  }, {
    path: "/mypage/contact/history/detail/confirm",
    component: _52a95038,
    name: "mypage-contact-history-detail-confirm"
  }, {
    path: "*",
    component: _23d2f299,
    name: "notfound"
  }, {
    path: "/",
    component: _1b3e9bd6,
    name: "lovot"
  }, {
    path: "/license",
    beforeEnter: (to, from, next) => {
          // Put the full page url including the protocol http(s) below
          window.location = 'https://lovot.life/terms/license/'
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
