const jaJP = {
  common: {
    dateFormatYYYYMD: 'YYYY年M月D日',
    dateFormatMD: 'M月D日',
    displayMonthTerm: 'ヶ月',
    currencyUnit: '¥',
    year: '年',
    month: '月',
    day: '日',
    lastYear: '直近1年',
    nest: 'ネスト',
    suffixName: '様',
    emptyName: '(名前がありません)', // カルーセルのLOVOT名称が無い場合の設定
    emptyDataLabel: '？', // LOVOT情報のステータスが無い場合の設定
    emptyRecord: '対象無し', // 治療・入院詳細画面のテーブル情報が空の時の設定
    message: {
      updated: '更新されました。'
    },
    fullWidthSpace: '　' // 名前のスペースで利用
  },
  error: {
    systemError: 'システムエラーが発生しました。',
    unauthorized: 'ユーザー認証に失敗しました。',
    downloadError: 'ダウンロードに失敗しました。'
  },
  validations: {
    interNetDisconnect: 'ネットワークに接続されていません。ネットワークの接続状況を確認してください。', // ネットワーク接続状態チェックエラー
    badRequest: '入力項目に不正な値があります。',
    notLogin: 'ログインされていません。ログイン画面に遷移します。',
    noContent: '登録されていません。登録されるまで数日かかる場合があります。',
    forbidden: 'アクセスが制限されています。',
    preconditionFailed: '時間を置いてからもう一度実行してください。',
    invalidBirthdate: '正しい生年月日を入力してください。',
    invalidZipcode: '郵便番号は上3桁、下4桁の半角数値で入力してください。',
    invalidPickupDate: '希望集荷日と時間をご選択ください。',
    invalidCallbackDateTime: '予約時間をご選択ください。',
    mismatchConfirmEmail: 'メールアドレスと確認メールアドレスが一致しません。',
    creditcard: {
      gmoError: 'クレジットカードの変更処理中にエラーが発生しました。LOVOTコンシェルジュにお問い合わせの際は [エラーコード：{errorCodes}] とお伝えください。',
      invalidExpireCorrelation: '有効期限が切れています。' // 相関チェックエラー
    },
    file: {
      overMaxSize: '添付ファイルは100MB以下を指定してください。',
      invalidFileExtension: '添付できるのは以下の種類のファイルのみです。',
      usuableExtentions: [
        '画像 (jpeg, jpg, png, bmp, heic)',
        '動画 (mp4, mpeg, mpg, flv, wmv, mov)',
        '音声 (wav, mp3, wma, m4a)',
        'PDF (pdf)'
      ]
    },
    faq: {
      invalidSearch: '検索方法が間違っています。',
      notFoundFaq: '検索された質問は存在しません。'
    }
  },
  headerLink: {
    webSite: 'LOVOT ブランドサイト',
    webStore: 'LOVOT ウェブストア',
    logout: 'ログアウト'
  },
  menu: {
    home: 'ホーム',
    news: 'お知らせ',
    newsLong: 'LOVOTコンシェルジュからのお知らせ',
    myaccount: 'お客様情報',
    hospital: '治療・入院',
    contractPayment: 'ご契約・お支払い',
    contract: 'ご契約中プラン',
    billingAddress: 'ご契約住所',
    creditcard: 'クレジットカード情報',
    billing: 'ご請求履歴',
    contactUs: 'お問い合わせ',
    contact: '入力フォーム',
    history: 'お問い合わせ履歴',
    officialSupporters: 'LOVOTオフィシャルサポーターズクラブ'
  },
  lovot: {
    titleWindow: 'LOVOT情報 | '
  },
  owners: {
    title: 'オーナー一覧',
    titleWindow: 'オーナー一覧 | '
  },
  update: {
    title: 'アップデート',
    titleWindow: 'アップデート | '
  },
  news: {
    title: 'お知らせ',
    titleWindow: 'お知らせ | '
  },
  myAccount: {
    title: 'お客様情報',
    titleWindow: 'お客様情報 | ',
    changeTitle: 'お客様情報変更 入力',
    changeTitleWindow: 'お客様情報変更 入力 | ',
    confirmTitle: 'お客様情報変更 確認',
    confirmTitleWindow: 'お客様情報変更 確認 | '
  },
  hospital: {
    title: '治療・入院',
    titleWindow: '治療・入院 | ',
    detailTitle: '治療・入院 詳細',
    detailTitleWindow: '治療・入院 詳細 | '
  },
  contractPlan: {
    title: 'ご契約中プラン',
    titleWindow: 'ご契約中プラン | ',
    afterDelivery: 'お届け後',
    afterPeriod: '経過後'
  },
  billingHistory: {
    title: 'ご請求履歴',
    titleWindow: 'ご請求履歴 | ',
    detailTitle: 'ご請求履歴 詳細',
    detailTitleWindow: 'ご請求履歴 詳細 | '
  },
  creditCard: {
    title: 'クレジットカード情報',
    titleWindow: 'クレジットカード情報 | ',
    changeTitle: 'クレジットカード情報変更 入力',
    changeTitleWindow: 'クレジットカード情報変更 入力 | ',
    confirmTitle: 'クレジットカード情報変更 確認',
    confirmTitleWindow: 'クレジットカード情報変更 確認 | '
  },
  billingAddress: {
    title: 'ご契約住所情報',
    titleWindow: 'ご契約住所情報 | ',
    changeTitle: 'ご契約住所変更 入力',
    changeTitleWindow: 'ご契約住所変更 入力 | ',
    confirmTitle: 'ご契約住所変更 確認',
    confirmTitleWindow: 'ご契約住所変更 確認 | '
  },
  contactMember: {
    wordJoiner: 'と',
    title: 'お問い合わせ',
    titleWindow: 'お問い合わせ 入力フォーム | ',
    confirmTitle: 'お問い合わせ 確認',
    confirmTitleWindow: 'お問い合わせ 確認 | ',
    completeTitle: 'お問い合わせ 完了',
    completeTitleWindow: 'お問い合わせ 完了 | '
  },
  contactHistory: {
    title: 'お問い合わせ履歴',
    titleWindow: 'お問い合わせ履歴 | ',
    accepting: '受付中',
    open: 'オープン',
    close: 'クローズ',
    unanswered: '未回答'
  },
  contactHistoryDetail: {
    title: 'お問い合わせ履歴 詳細',
    titleWindow: 'お問い合わせ履歴 詳細 | ',
    confirmTitle: 'お問い合わせ履歴 確認',
    confirmTitleWindow: 'お問い合わせ履歴 確認 | ',
    completeTitle: 'お問い合わせ履歴 完了',
    completeTitleWindow: 'お問い合わせ履歴 完了 | ',
    supportCenter: 'コンシェルジュ'
  },
  contactGuest: {
    title: 'お問い合わせ 入力',
    titleWindow: 'お問い合わせ 入力 | ',
    confirmTitle: 'お問い合わせ 確認',
    confirmTitleWindow: 'お問い合わせ 確認 | ',
    completeTitle: 'お問い合わせ 完了',
    completeTitleWindow: 'お問い合わせ 完了 | '
  },
  member: {
    title: 'お客様情報の登録',
    titleWindow: 'お客様情報の登録 | ',
    confirmTitle: 'お客様情報の確認 ',
    confirmTitleWindow: 'お客様情報の登録 | ',
    completeTitle: 'お客様情報の登録',
    completeTitleWindow: 'お客様情報の登録 | '
  },
  faq: {
    title: 'よくある質問（FAQ）',
    titleWindow: 'よくある質問（FAQ） | '
  },
  officialSupporters: {
    title: 'LOVOTオフィシャルサポーターズクラブ',
    titleWindow: 'LOVOTオフィシャルサポーターズクラブ | '
  },
  errorScreen: {
    notFound: {
      title: '404 NOT FOUND',
      titleWindow: '404 | '
    },
    error: {
      title: '503 SERVICE UNAVAILABLE',
      titleWindow: '503 | '
    }
  },
  role: {
    su: {
      val: 'SU',
      text: '管理者'
    },
    fu: {
      val: 'FU',
      text: 'ファミリー'
    }
  }
}

export default jaJP
