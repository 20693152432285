<template>
  <div class="c-navi">
    <div class="c-navi--message">
      {{ notifyMessage }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Ribon',
  data() {
    return {
      notifyMessage: null
    }
  },
  computed: {
    ...mapState('notify', ['message'])
  },
  created() {
    if (this.message) {
      this.notifyMessage = this.message
    } else {
      this.notifyMessage = this.$t('common.message.updated')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/foundation/base.scss';

.c-navi {
  background-color: color-brand(main, 1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .c-navi--message {
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: 0.72px;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    color: color-brand(white);

    @include desktop {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
</style>
