export const state = () => ({
  form: null,
  colonies: null,
  apiError: null,
  access: false,
  targetLovotVisible: false
})

export const getters = {
  targetLovotVisible: (state) => {
    return state.targetLovotVisible
  },
  contactForm: (state) => {
    return state.form
  },
  colonies: (state) => {
    return state.colonies
  },
  canAccess: (state) => {
    return state.access
  },
  apiError: (state) => {
    return state.apiError
  }
}

export const mutations = {
  targetLovotVisible: (state, visible) => {
    state.targetLovotVisible = visible
  },
  setContactForm(state, form) {
    state.form = form
    state.apiError = null
  },
  setColonies(state, colonies) {
    state.colonies = colonies
    state.apiError = null
  },
  setAccessible(state, value = true) {
    state.access = value
  },
  setApiError(state, error) {
    state.access = true
    state.apiError = error
  },
  clear(state) {
    state.form = null
    state.apiError = null
    state.access = false
    state.targetLovotVisible = false
  }
}
